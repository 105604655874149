<script>
export default {
  emits: ["link-clicked"],
  methods: {
    handleClick(e) {
      if (e.target.localName !== "a") return;
      this.$emit("link-clicked");
    },
  },
};
</script>

<template>
  <nav class="nav" @click="handleClick($event)">
    <ul>
      <li data-aos="fade-right" data-aos-duration="1000"><router-link to="/">Home</router-link></li>
      <li data-aos="fade-left" data-aos-duration="1000"><router-link to="/about">About</router-link></li>
      <li data-aos="fade-right" data-aos-duration="1000"><a href="/#work">Films</a></li>
      <li data-aos="fade-left" data-aos-duration="1000"><router-link to="/Contact">Contact</router-link></li>
    </ul>
  </nav>
</template>
