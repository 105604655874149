import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/*webpackChunkName:"About"*/ "@/views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/*webpackChunkName:"Contact"*/ "@/views/Contact.vue"),
  },
  {
    path: "/jihan",
    name: "Jihan",
    component: () => import(/*webpackChunkName:"Jihan"*/ "@/views/Jihan.vue"),
  },
  {
    path: "/team:id",
    name: "Details",
    props: true,
    component: () =>
      import(/*webpackChunkName:"Details"*/ "@/views/Details.vue"),
  },
  /*404*/
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/*webpackChunkName:"NotFound"*/ "@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "history",
  //Hash TO FROM//
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }
    return { top: 0, bottom: 0 };
  },
});

export default router;
