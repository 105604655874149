<script>
import Menu from "@/components/Menu.vue";
import Loader from "@/components/Loader.vue";
export default {
  components: {
    Menu,
    Loader,
  },
  data() {
    return {
      menuVisible: false,
      active: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      this.active = !this.active;
      document.body.classList.toggle("noScroll");
    },
  },
};
</script>

<template>
  <div class="menu">
    <a @click="toggleMenu" class="hamburger" :class="{ active: active }">
      <span></span>
    </a>
    <!-- <span
      class="logo"
      :style="{ display: $route.path === '/' ? 'none' : 'block' }"
    >
      <router-link to="/"><p>big <br />Sister</p><small>production</small></router-link>
    </span> -->
    <transition name="slide">
      <Menu v-if="menuVisible" @linkClicked="toggleMenu"></Menu>
    </transition>
  </div>
  <!--END NAV MENU-->

  <!--VIEW-->
  <router-view v-slot="{ Component, route }">
    <Loader />
    <transition name="slide" mode="out-in">
      <component :is="Component" :key="route.path" id="hero" />
    </transition>
  </router-view>
  <!--Cursor-->
  <!-- <div class="cursor-dot-outline"></div>
  <div class="cursor-dot"></div> -->
  <!-- <a class="goTop" href="#hero">top</a> -->
  <footer>&copy; 2021 big Sister Production</footer>
</template>
<style scoped lang="scss">
.goTop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: none;
  &.show {
    display: block !important;
  }
}
</style>
