//MAIN CSS
import "@/assets/css/main.scss";
//VUE
import { createApp } from "vue";
import router from "@/router";
import app from "@/App.vue";
// import { VueCookieNext } from "vue-cookie-next";

createApp(app)
  .use(router)
  // .use(VueCookieNext)
  .mount("body");
// set default config
// VueCookieNext.config({ expire: "7d" });

// set global cookie
// VueCookieNext.setCookie("theme", "default");
// VueCookieNext.setCookie("hover-time", { expire: "1s" });
