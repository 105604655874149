<script>
import axios from "axios"
import Modal from "./Modal";
export default {
  components: { Modal },
  data() {
    axios.get(`https://dashboard.bigsisterprod.com/api/films/2`).then((response) => (this.films = response.data));
    return {
      films: [],
      showModal: false,
    };
  },
  methods: {
    openModal(indev) {
      this.showModal = true;
      this.indev = indev;
      document.body.classList.add("noScroll");
    },
    closeModal(indev) {
      this.showModal = false;
      this.indev = indev;
      document.body.classList.remove("noScroll");
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="content">
      <h1
        class="title"
        style="font-size: 4em; margin: 0.5em 0em .5em 0em"
        data-aos="fade-right"
        data-aos-duration="1000"
      >
        In <br />Development
      </h1>

      <ul class="work_row soon">
        <li
          v-for="indev in films"
          :key="indev"
          data-aos="fade-down"
          data-aos-duration="1000"
          @click="openModal(indev)"
        >
          <img :src="'https://dashboard.bigsisterprod.com/' + indev.image" alt="" />
          <div class="infos">
            <h1>{{ indev.title }}</h1>
            <h2>{{ indev.director }}</h2>
            <h3>{{ indev.year }}</h3>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!--Modal-->
  <transition name="slide">
    <Modal v-if="showModal" @close="closeModal(indev)">
      <template v-slot:body>
        <main class="modal__content" id="info">
          <div class="info">
            <div class="info__left">
              <h1>{{ indev.title }}</h1>
              <ul>
                <li>by {{ indev.director }}</li>
                <li>{{ indev.year }}</li>
                <li>{{ indev.type }}</li>
              </ul>
            </div>
            <div class="info__mid">
              <h3>Synopsis</h3>
              <div v-html="indev.synopsis"></div>
            </div>
            <div
              class="info__right"
              v-if="indev.awards && indev.awards.length > 0"
            >
              <h3 style="text-align: center">Awards & Festivals</h3>
              <ul class="awardList">
                <li v-for="award in indev.awards" :key="award">
                  {{ award.name }}
                </li>
              </ul>
            </div>
          </div>
        </main>
      </template>
    </Modal>
  </transition>
  <!--End Modal-->
</template>
