<script>
import Main from "@/components/Main";
import Cards from "@/components/Cards";
import Soon from "@/components/Soon";
import axios from "axios";

export default {
  data() {
    axios
      .get(`https://dashboard.bigsisterprod.com/api/updates`)
      .then((response) => (this.updates = response.data));
    axios
      .get(`https://dashboard.bigsisterprod.com/api/vision`)
      .then((response) => (this.vision = response.data));
    return {
      updates: [],
      vision: [],
    };
  },
  components: {
    Main,
    Cards,
    Soon,
  },
};
</script>

<template>
  <div>
    <Main />
    <section class="vision">
      <div class="container">
        <div class="updates" data-aos="fade-left" data-aos-duration="1000">
          <h1>Updates</h1>
          <p v-for="update in updates" :key="update.id">
            {{ update.content }}
          </p>
        </div>

        <div class="content">
          <h1 class="title" data-aos="fade-right" data-aos-duration="1000">
            Our <br />Vision
          </h1>
          <p data-aos="fade-down" v-html="vision.content" data-aos-duration="1000"></p>
        </div>
      </div>
    </section>
    <Cards />
    <Soon />
  </div>
</template>
