<script>
import Circle from "./Circle.vue";
export default {
  components: {
    Circle,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.isLoaded = true;
    // }, 3000);
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isLoaded = true;
      }
    };
  },
};
</script>

<template>
  <transition name="fade">
    <div class="load" v-if="!isLoaded">
      <!-- <h2>IS WATCHING YOU</h2> -->
      <Circle>big sister is watching you</Circle>
    </div>
  </transition>
</template>

<style lang="scss">
.load {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background: #000;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 1s ease;
  #circle {
    width: 100%;
    margin: 0 auto;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
  }
}
</style>
