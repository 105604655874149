<script>
import Circle from "./Circle";
export default {
  components: {
    Circle,
  },
  mounted() {
    var video = document.getElementById("video");
    video.muted = true;
  },
};
</script>

<template>
  <section class="main">
    <div class="container">
      <h1 class="bigLogo" data-splitting>big sister <br /><small>productions</small></h1>
      <div class="vid">
        <video src="vids/main.mp4" id="video" autoplay loop></video>
      </div>

      <a href="/#work"><Circle>our films our films</Circle></a>

      <!-- <div class="updates" data-aos="fade-down" data-aos-duration="2000">
        <h1>Updates</h1>
        <p>
          Mariner of the Mountains by Karim Aïnouz has been presented in the
          Official Selection of the 2021 Cannes International Film Festival as
          part of the Special Screenings.
        </p>
      </div> -->
    </div>
  </section>
</template>
