<script>
import Modal from "./Modal";
import axios from "axios";
export default {
  data() {
    axios
      .get(`https://dashboard.bigsisterprod.com/api/films`)
      .then((response) => (this.films = response.data));
    return {
      films: [],
      showModal: false,
    };
  },

  components: {
    Modal,
  },

  methods: {
    openModal(film) {
      this.showModal = true;
      this.film = film;
      document.body.classList.add("noScroll");
    },
    closeModal(film) {
      this.showModal = false;
      this.film = film;
      document.body.classList.remove("noScroll");
    },
  },

  updated() {
    const videos = document.querySelectorAll(".thumbnail");
    videos.forEach((video) => {
      video.addEventListener("mouseover", function() {
        this.play();
      });

      video.addEventListener("mouseout", function() {
        this.pause();
        this.currentTime = 0;
      });
    });
    //Sort Films//
    this.films.sort((a, b) => (a.year < b.year ? 1 : -1));
  },
};
</script>

<template>
  <section class="work" id="work">
    <div class="container">
      <h1 class="title" data-aos="fade-right" data-aos-duration="1000">
        Our <br />Films
      </h1>
      <div class="masonry">
        <div
          v-for="film in films"
          :key="film.id"
          class="brick"
          @click="openModal(film)"
        >
          <!-- <div id="infohov">info</div> -->
          <!-- <img
            v-if="film.image && film.image.length > 0"
            class="screen"
            :src="'https://dashboard.bigsisterprod.com/' + film.image"
            alt=""
          /> -->
          <video
            v-if="film.thumbnail && film.thumbnail.length > 0"
            :src="`https://dashboard.bigsisterprod.com/` + film.thumbnail"
            loop
            muted
            class="thumbnail"
            type="video/mp4"
          />
          <div class="info">
            <h1>{{ film.title }}</h1>
            <h3>{{ film.director }}</h3>
            <h4>{{ film.year }}</h4>
          </div>
        </div>
      </div>
      <!---->
    </div>
  </section>
  <!--Modal-->
  <transition name="slide">
    <Modal v-if="showModal" @close="closeModal(film)">
      <template v-slot:body>
        <div class="vid" v-if="film.video_url && film.video_url.length > 0">
          <iframe
            :src="film.video_url"
            width="100%"
            height="100%"
            frameborder="0"
            title="trailer"
            allowfullscreen
          ></iframe>
        </div>
        <a href="#info" class="infobtn">
          <span>scroll for info</span>
          <img src="imgs/down-arrow.gif" height="25" alt="" />
        </a>
        <main class="modal__content" id="info">
          <div class="info">
            <div class="info__left">
              <h1>{{ film.title }}</h1>
              <ul>
                <li>by {{ film.director }}</li>
                <li>{{ film.year }}</li>
                <li>{{ film.type }}</li>
                <li>{{ film.length }} min</li>
              </ul>
            </div>
            <div class="info__mid">
              <h3>Synopsis</h3>
              <div v-html="film.synopsis"></div>
            </div>
            <div
              class="info__right"
              v-if="film.awards && film.awards.length > 0"
            >
              <h3 style="text-align: center">Awards & Festivals</h3>
              <ul class="awardList">
                <li v-for="award in film.awards" :key="award">
                  {{ award.name }}
                </li>
              </ul>
            </div>
          </div>
        </main>
      </template>
    </Modal>
  </transition>
  <!--End Modal-->
</template>
